@media (max-width: 320px) {
  .text_anim {
    top: 5%;
    left: 81%;
    width: 80%;
    height: 157px;
    font-size: 32px;
    transform: translateX(-81%);
  }
  .line_anim {
    top: 14%;
    left: 10%;
  }
  .Birds_anim {
    transform: none;
    opacity: 1;
    width: 88px;
    left: 20%;
    transform: translateX(-20%);
    top: 27%;
  }
  .pathhide_anim {
    left: 50%;
    transform: scale(0.49) rotate(8deg) translateX(-50%);
    top: 26%;
  }
  .Cloud2_anim {
    transform: none;
    top: 299px;
    left: 12%;
    width: 126px;
    transform: translateX(-12%);
  }
  .Cloud1_anim {
    transform: none;
    top: 367px;
    right: 0%;
    width: 122px;
  }
  .Mountain1_anim {
    right: 4%;
    transform: translateX(-4%);
    top: 665px;
    width: 150px;
  }
  .geo2_anim {
    transform: none;
    left: 17%;
    top: 622px;
    width: 110px;
    transform: translateX(-17%);
  }
  .Tree_anim {
    opacity: 0;
  }
  .backbox {
    width: 185px;
    bottom: 0;
    height: 20px;
  }
}

@media (min-width: 321px) and (max-width: 575.98px) {
  .text_anim {
    top: 5%;
    left: 81%;
    width: 80%;
    height: 157px;
    font-size: 32px;
    transform: translateX(-81%);
  }
  .line_anim {
    top: 14%;
    left: 10%;
  }
  .Birds_anim {
    transform: none;
    opacity: 1;
    width: 88px;
    left: 20%;
    transform: translateX(-20%);
    top: 27%;
  }
  .pathhide_anim {
    left: 50%;
    transform: scale(0.6) rotate(8deg) translateX(-50%);
    top: 26%;
  }
  .Cloud2_anim {
    transform: none;
    top: 299px;
    left: 12%;
    width: 126px;
    transform: translateX(-12%);
  }
  .Cloud1_anim {
    transform: none;
    top: 410px;
    right: 2%;
    width: 141px;
    transform: translateX(-2%);
  }
  .Mountain1_anim {
    right: 4%;
    transform: translateX(-4%);
    top: 665px;
    width: 150px;
  }
  .geo2_anim {
    transform: none;
    left: 17%;
    top: 683px;
    width: 110px;
    transform: translateX(-17%);
  }
  .Tree_anim {
    opacity: 0;
  }
  .backbox {
    width: 185px;
    bottom: 0;
    height: 20px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .text_anim {
    top: 5%;
    left: 81%;
    width: 80%;
    height: 157px;
    font-size: 32px;
    transform: translateX(-81%);
  }
  .line_anim {
    top: 14%;
    left: 10%;
  }
  .Birds_anim {
    transform: none;
    opacity: 1;
    width: 88px;
    left: 26%;
    transform: translateX(-26%);
    top: 27%;
  }
  .pathhide_anim {
    left: 50%;
    transform: scale(0.7) rotate(8deg) translateX(-50%);
    top: 26%;
  }
  .Cloud2_anim {
    transform: none;
    top: 299px;
    left: 21%;
    width: 135px;
    transform: translateX(-21%);
  }
  .Cloud1_anim {
    transform: none;
    top: 410px;
    right: 2%;
    width: 160px;
    transform: translateX(-2%);
  }
  .Mountain1_anim {
    right: 4%;
    transform: translateX(-4%);
    top: 665px;
    width: 160px;
  }
  .geo2_anim {
    transform: none;
    left: 17%;
    top: 683px;
    width: 110px;
    transform: translateX(-17%);
  }
  .Tree_anim {
    opacity: 0;
  }
  .backbox {
    width: 185px;
    bottom: 0;
    height: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .text_anim {
    top: 5%;
    left: 81%;
    width: 80%;
    height: 120px;
    font-size: 32px;
    transform: translateX(-81%);
  }
  .line_anim {
    top: 11%;
    left: 10%;
  }
  .Birds_anim {
    transform: none;
    opacity: 1;
    width: 88px;
    left: 26%;
    transform: translateX(-26%);
    top: 27%;
  }
  .pathhide_anim {
    left: 50%;
    transform: scale(0.8) rotate(10deg) translateX(-50%);
    top: 26%;
  }
  .Cloud2_anim {
    transform: none;
    top: 299px;
    left: 21%;
    width: 135px;
    transform: translateX(-21%);
  }
  .Cloud1_anim {
    transform: none;
    top: 410px;
    right: 6%;
    width: 174px;
    transform: translateX(-6%);
  }
  .Mountain1_anim {
    right: 9%;
    transform: translateX(-9%);
    top: 665px;
    width: 177px;
  }
  .geo2_anim {
    transform: none;
    left: 17%;
    top: 683px;
    width: 110px;
    transform: translateX(-17%);
  }
  .Tree_anim {
    opacity: 1;
    left: 59%;
    transform: none;
    width: 100px;
    top: 35%;
  }
  .backbox {
    width: 185px;
    bottom: 0;
    height: 20px;
  }
}

@media (min-width: 1200px) and (max-width: 1445px) {
  .text_anim {
    top: 25%;
  }
  .pathhide_anim {
    top: 18%;
    left: 52%;
  }
  .geo2_anim {
    top: 40%;
  }
  .Tree_anim {
    top: 15%;
  }
}

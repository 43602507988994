@media (max-width: 320px) {
  .pathhide {
    left: 50%;
    transform: scale(0.3) translateX(-50%);
    top: 15%;
  }
  .Tree {
    opacity: 0;
  }
  .text {
    top: 5%;
    transform: translateX(-5%);
    left: 18%;
    width: 80%;
    height: 79px;
    font-size: 32px;
  }
  .line {
    top: 8%;
    left: 10%;
    transform: translateX(-10%);
    width: 100%;
  }
  .Cloud2 {
    transform: none;
    top: 177px;
    left: 15%;
    transform: translateX(-15%);
    width: 96px;
  }
  .Cloud1 {
    transform: none;
    top: 342px;
    right: 10%;
    transform: translateX(-10%);
    width: 96px;
  }
  .Mountain1 {
    right: 10%;
    transform: translateX(-10%);
    transform: none;
    top: 400px;
    width: 100px;
  }
  .geo2 {
    transform: none;
    left: 19%;
    transform: translateX(-19%);
    top: 400px;
    width: 93px;
  }
  .Birds {
    transform: none;
    opacity: 0;
    width: 88px;
    left: 100%;
    top: 27%;
  }
}
@media (min-width: 321px) and (max-width: 1199.98px) {
  .Tree {
    opacity: 0;
  }
  .text {
    top: 5%;
    transform: translateX(-5%);
    left: 18%;
    width: 80%;
    height: 79px;
    font-size: 32px;
  }
  .line {
    top: 8%;
    left: 10%;
    transform: translateX(-10%);
    width: 100%;
  }
  .pathhide {
    left: 50%;
    transform: scale(0.4) translateX(-50%);
    top: 23%;
  }
  .Cloud2 {
    transform: none;
    top: 177px;
    left: 15%;
    transform: translateX(-15%);
    width: 96px;
  }
  .Cloud1 {
    transform: none;
    top: 342px;
    right: 10%;
    transform: translateX(-10%);
    width: 96px;
  }
  .Mountain1 {
    right: 10%;
    transform: translateX(-10%);
    transform: none;
    top: 665px;
    width: 100px;
  }
  .geo2 {
    transform: none;
    left: 19%;
    transform: translateX(-19%);
    top: 600px;
    width: 93px;
  }
  .Birds {
    transform: none;
    opacity: 0;
    width: 88px;
    left: 100%;
    top: 27%;
  }
}

@media (min-width: 1200px) and (max-width: 1445px) {
  .text {
    top: 25%;
  }
  .pathhide {
    top: 13%;
  }
  .geo2 {
    top: 35%;
  }
}

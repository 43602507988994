.all_img-bg {
  position: absolute;
}

.agave_bg {
  left: 300px;
  top: 3500px;
}

.aloe_bg {
  top: 4300px;
  left: 1100px;
  transform: scale(1.5);
}

.bear_bg {
  top: 4300px;
  left: 400px;
  transform: scale(1.5);
}

.big-birds_bg {
  top: 3700px;
  left: 1070px;
  transform: scale(2);
}

.butterflies_bg {
  top: 4000px;
  left: 200px;
}

.butterflies-two_bg {
  top: 4700px;
  left: 1200px;
  transform: scale(1.5);
}

.cacti_bg {
  top: 4900px;
  left: 400px;
  transform: scale(4);
}

.cat_bg {
  top: 5500px;
  left: 700px;
  transform: scale(1.5);
}

.corn_bg {
  top: 5100px;
  left: 1200px;
}

.fir-trees_bg {
  top: 6500px;
  left: 400px;
  transform: scale(2.5);
}

.flowers_bg {
  top: 5900px;
  left: 500px;
  transform: scale(2);
}

.glasses_bg {
  top: 9000px;
  left: 250px;
}

.moth_bg {
  top: 8600px;
  left: 1300px;
}

.pond_bg {
  top: 7800px;
  left: 300px;
}

.skyscrapers_bg {
  top: 7200px;
  left: 500px;
  transform: scale(2.5);
}

.wolf_bg {
  top: 8450px;
  left: 600px;
  transform: scale(1.5);
}

.deer_bg {
  top: 6800px;
  left: 1250px;
  transform: scale(1.5);
}

.corn-two_bg {
  top: 7600px;
  left: 700px;
}

.canyon_bg {
  top: 9500px;
  left: 1100px;
}

.palm-trees_bg {
  top: 9200px;
  right: 550px;
  transform: scale(0.3);
}

@media (min-width: 1729px) {
  .corn_bg {
    left: 1300px;
  }

  .deer_bg {
    left: 1400px;
  }

  .canyon_bg {
    top: 9200px;
    left: 1300px;
  }
}

@media (min-width: 1779px) {
  .big-birds_bg {
    left: 1300px;
  }

  .butterflies-two_bg {
    left: 1400px;
  }

  .corn_bg {
    left: 1500px;
  }

  .moth_bg {
    top: 8800px;
    left: 1500px;
  }
}

@media (min-width: 1939px) {
  .deer_bg {
    left: 1600px;
  }

  .corn_bg {
    left: 1600px;
  }

  .aloe_bg {
    left: 1500px;
    transform: scale(2.5);
  }

  .big-birds_bg {
    left: 1470px;
    transform: scale(3);
  }

  .bear_bg {
    transform: scale(2.5);
  }

  .canyon_bg {
    left: 1500px;
  }

  .glasses_bg {
    left: 450px;
    transform: scale(1.5);
  }

  .moth_bg {
    left: 1700px;
    transform: scale(1.5);
  }
}

@media (min-width: 2159px) {
  .butterflies-two_bg {
    left: 1800px;
  }

  .corn_bg {
    left: 1850px;
  }

  .deer_bg {
    left: 1900px;
  }

  .canyon_bg {
    left: 1800px;
  }

  .moth_bg {
    left: 1900px;
  }

  .pond_bg {
    top: 8000px;
    left: 800px;
    transform: scale(2);
  }

  .fir-trees_bg {
    top: 6600px;
    left: 800px;
    transform: scale(3.5);
  }

  .aloe_bg {
    left: 1700px;
    transform: scale(2.5);
  }

  .butterflies_bg {
    top: 3900px;
    left: 600px;
    transform: scale(1.5);
  }

  .big-birds_bg {
    left: 1700px;
    transform: scale(3);
  }
}

@media (min-width: 2349px) {
  .agave_bg {
    left: 750px;
    top: 3500px;
    transform: scale(2);
  }

  .big-birds_bg {
    left: 1900px;
  }

  .aloe_bg {
    left: 2000px;
  }

  .cacti_bg {
    top: 4900px;
    left: 800px;
    transform: scale(6);
  }

  .butterflies-two_bg {
    left: 2100px;
  }

  .deer_bg,
  .moth_bg,
  .corn_bg {
    left: 2100px;
  }

  .canyon_bg {
    left: 2000px;
  }

  .corn-two_bg {
    left: 1000px;
  }

  .wolf_bg {
    top: 8600px;
    left: 1180px;
    transform: scale(2.5);
  }
}

@media (max-width: 1441px) {
  .bear_bg {
    left: 250px;
  }

  .cat_bg {
    left: 500px;
  }

  .corn-two_bg {
    top: 7600px;
    left: 600px;
  }

  .aloe_bg {
    left: 900px;
  }

  .butterflies-two_bg {
    left: 1000px;
  }

  .corn_bg {
    left: 1100px;
  }

  .deer_bg {
    left: 1050px;
  }

  .moth_bg {
    left: 1100px;
  }

  .canyon_bg {
    left: 950px;
  }

  .palm-trees_bg {
    right: 400px;
    transform: scale(0.25);
  }
}

@media (max-width: 1351px) {
  .big-birds_bg {
    left: 700px;
  }

  .agave_bg {
    left: 150px;
  }

  .corn_bg {
    left: 950px;
  }

  .cacti_bg {
    left: 200px;
    transform: scale(3);
  }

  .flowers_bg {
    left: 300px;
  }

  .skyscrapers_bg {
    left: 400px;
    transform: scale(2);
  }

  .corn-two_bg {
    left: 400px;
  }

  .wolf_bg {
    left: 300px;
  }
}

@media (max-width: 1386px) {
  .bear_bg {
    left: 150px;
  }

  .aloe_bg {
    left: 600px;
  }

  .butterflies-two_bg {
    left: 800px;
  }

  .palm-trees_bg {
    transform: scale(0.17);
  }

  .canyon_bg {
    left: 800px;
    transform: scale(0.8);
  }

  .glasses_bg {
    top: 9000px;
    left: 150px;
    transform: scale(0.8);
  }
}

@media (max-width: 1251px) {
  .palm-trees_bg {
    right: 300px;
    top: 9200px;
  }

  .corn_bg {
    top: 5000px;
    transform: scale(0.8);
  }

  .moth_bg {
    left: 900px;
  }

  .skyscrapers_bg {
    left: 300px;
    transform: scale(1.7);
  }

  .fir-trees_bg {
    left: 250px;
    transform: scale(2);
  }
}

@media (max-width: 1185px) {
  .deer_bg {
    top: 6500px;
    left: 850px;
  }

  .corn_bg {
    transform: scale(0.6);
    left: 850px;
  }

  .flowers_bg {
    left: 250px;
    transform: scale(1.7);
  }
}

@media (max-width: 1101px) {
  .aloe_bg {
    top: 4450px;
    left: 600px;
  }

  .butterflies-two_bg {
    top: 4700px;
    left: 900px;
    transform: scale(1);
  }

  .cat_bg {
    left: 300px;
  }

  .deer_bg {
    top: 6600px;
    left: 900px;
    transform: scale(1);
  }

  .corn-two_bg {
    left: 200px;
    transform: scale(0.7);
  }

  .pond_bg {
    top: 7750px;
    left: 100px;
    transform: scale(0.8);
  }

  .wolf_bg {
    top: 6850px;
    left: 300px;
    transform: scale(1);
  }

  .skyscrapers_bg {
    left: 200px;
    transform: scale(1.2);
  }

  .moth_bg {
    top: 7500px;
    left: 900px;
  }

  .canyon_bg {
    top: 7800px;
    left: 650px;
    transform: scale(0.5);
  }

  .palm-trees_bg {
    right: 100px;
    top: 7650px;
    transform: scale(0.13);
  }

  .glasses_bg {
    top: 5382px;
    left: 246px;
    transform: scale(0.4) rotate(3.5deg);
  }
}

@media (max-width: 1031px) {
  .big-birds_bg {
    left: 600px;
    transform: scale(1.3);
  }

  .aloe_bg {
    top: 4500px;
    left: 400px;
    transform: scale(1.2);
  }

  .butterflies-two_bg {
    left: 830px;
  }

  .corn_bg {
    transform: scale(0.5);
    left: 780px;
  }

  .moth_bg {
    left: 750px;
  }

  .corn-two_bg {
    top: 7650px;
    left: 200px;
    transform: scale(0.5);
  }

  .deer_bg {
    left: 800px;
  }
}

@media (max-width: 951px) {
  .big-birds_bg {
    left: 500px;
  }

  .aloe_bg {
    top: 4600px;
    left: 350px;
    transform: scale(1);
  }

  .butterflies-two_bg {
    top: 4500px;
    left: 650px;
    transform: scale(1);
  }

  .corn_bg {
    transform: scale(0.5);
    left: 690px;
    top: 5150px;
  }

  .cacti_bg {
    top: 5100px;
    left: 300px;
    transform: scale(3);
  }

  .flowers_bg {
    left: 170px;
    transform: scale(1);
  }

  .fir-trees_bg {
    left: 650px;
    transform: scale(1);
    top: 5900px;
  }

  .palm-trees_bg {
    right: 0px;
    top: 5890px;
    transform: scale(0.1);
  }

  .canyon_bg {
    top: 6120px;
    left: 580px;
    transform: scale(0.5);
  }

  .deer_bg,
  .wolf_bg,
  .skyscrapers_bg,
  .moth_bg,
  .corn-two_bg,
  .pond_bg {
    display: none;
  }
}

@media (max-width: 841px) {
  .corn_bg {
    left: 600px;
  }

  .cat_bg {
    top: 5500px;
    left: 200px;
    transform: scale(1);
  }

  .glasses_bg {
    top: 5400px;
    left: 141px;
    transform: scale(0.2) rotate(3.5deg);
  }

  .cacti_bg {
    transform: scale(2);
  }
}

@media (max-width: 811px) {
  .butterflies-two_bg {
    left: 450px;
  }

  .skyscrapers_bg {
    display: block;
    top: 7000px;
  }

  .pond_bg {
    display: block;
    top: 6800px;
    left: 500px;
    transform: scale(0.8);
  }

  .agave_bg {
    left: 100px;
    transform: scale(0.7);
  }

  .corn_bg {
    left: 550px;
  }

  .canyon_bg {
    top: 6220px;
    left: 530px;
    transform: scale(0.5);
  }
}

@media (max-width: 755px) {
  .skyscrapers_bg {
    left: 140px;
  }

  .pond_bg {
    left: 450px;
  }

  .fir-trees_bg {
    left: 590px;
  }

  .cacti_bg {
    top: 5000px;
    left: 200px;
  }

  .butterflies-two_bg {
    left: 600px;
  }
}

@media (max-width: 711px) {
  .butterflies_bg {
    top: 3900px;
  }

  .aloe_bg {
    top: 4700px;
    left: 500px;
    transform: scale(1);
  }

  .corn_bg {
    left: 500px;
  }

  .cacti_bg {
    left: 120px;
  }

  .palm-trees_bg {
    right: -120px;
    top: 5750px;
  }

  .flowers_bg {
    left: 90px;
    top: 5800px;
  }

  .canyon_bg {
    top: 6020px;
    left: 400px;
    transform: scale(0.4);
  }

  .fir-trees_bg {
    left: 550px;
    transform: scale(1);
    top: 5600px;
  }

  .bear_bg {
    top: 4200px;
    left: 150px;
    transform: scale(1);
  }

  .cat_bg {
    left: 150px;
  }

  .glasses_bg {
    left: 91px;
  }

  .skyscrapers_bg,
  .pond_bg {
    display: none;
  }

  .butterflies_bg {
    left: 150px;
  }
}
@media (max-width: 320px) {
  .pathhide_anim3 {
    top: 110%;
    left: 50%;
    transform: scale(0.7) rotate(2deg) translateX(-50%);
  }
  @keyframes pathhideanim {
    0% {
      left: 50%;
      transform: scale(0.49) rotate(8deg) translateX(-50%);
      top: 26%;
    }
    100% {
      top: 110%;
      left: 50%;
      transform: scale(0.7) rotate(2deg) translateX(-50%);
    }
  }

  .Misc4_anim {
    transform: none;
    width: 60px;
    top: 83%;
    left: 15%;
  }
  .misc1_anim {
    transform: none;
    width: 69px;
    top: 96%;
    right: 4px;
  }
  .geo2_anim3 {
    transform: none;
    left: 17%;
    top: 932px;
    width: 114px;
    transform: translateX(-17%);
  }
  .Geo1_anim3 {
    transform: none;
    width: 100px;
    left: 17%;
    transform: translateX(-17%);
  }

  .trees1_anim,
  .Tree_anim3 {
    display: none;
  }
  .Mountain1_anim3 {
    right: 0%;
    transform: translateX(0%);
    top: 1100px;
    width: 150px;
  }
  .bottomtext {
    display: none;
  }
}

@media (min-width: 321px) and (max-width: 470px) {
  .pathhide_anim3 {
    top: 84%;
    left: 50%;
    transform: scale(0.7) rotate(2deg) translateX(-50%);
  }
  @keyframes pathhideanim {
    0% {
      left: 50%;
      transform: scale(0.6) rotate(8deg) translateX(-50%);
      top: 26%;
    }
    100% {
      top: 84%;
      left: 50%;
      transform: scale(0.7) rotate(2deg) translateX(-50%);
    }
  }

  .Misc4_anim {
    transform: none;
    width: 60px;
    top: 83%;
    left: 15%;
  }
  .misc1_anim {
    transform: none;
    width: 69px;
    top: 96%;
    right: 4px;
  }
  .geo2_anim3 {
    transform: none;
    left: 17%;
    top: 932px;
    width: 114px;
    transform: translateX(-17%);
  }
  .Geo1_anim3 {
    transform: none;
    width: 100px;
    left: 21%;
    transform: translateX(-21%);
  }
  .Tree_anim3 {
    transform: none;
    width: 79px;
    top: 117%;
    left: 68%;
  }
  .Mountain1_anim3 {
    right: 0%;
    transform: translateX(0%);
    top: 1100px;
    width: 150px;
  }
  .bottomtext {
    display: none;
  }
  .trees1_anim {
    display: none;
  }
  .geo3_anim {
    transform: none;
    width: 100px;
    right: 10%;
    top: 1788px;
  }
  .misc2_anim {
    transform: none;
    width: 88px;
    top: 1709px;
    left: 73px;
  }
}

@media (min-width: 321px) and (max-width: 350px) {
  .pathhide_anim3 {
    top: 84%;
    left: 50%;
    transform: scale(0.7) rotate(2deg) translateX(-50%);
  }
  @keyframes pathhideanim {
    0% {
      left: 50%;
      transform: scale(0.6) rotate(8deg) translateX(-50%);
      top: 26%;
    }
    100% {
      top: 84%;
      left: 50%;
      transform: scale(0.7) rotate(2deg) translateX(-50%);
    }
  }

  .Misc4_anim {
    transform: none;
    width: 60px;
    top: 83%;
    left: 15%;
  }
  .misc1_anim {
    transform: none;
    width: 69px;
    top: 96%;
    right: 4px;
  }
  .geo2_anim3 {
    transform: none;
    left: 17%;
    top: 932px;
    width: 114px;
    transform: translateX(-17%);
  }
  .Geo1_anim3 {
    transform: none;
    width: 100px;
    left: 10%;
    transform: scale(0.8);
  }
  .Tree_anim3 {
    display: none;
  }
  .Mountain1_anim3 {
    right: 0%;
    transform: translateX(0%);
    top: 1100px;
    width: 150px;
  }
  .bottomtext {
    display: none;
  }
  .trees1_anim {
    transform: none;
    width: 100px;
    top: 240%;
  }
  .geo3_anim {
    transform: none;
    width: 100px;
    right: 10%;
    top: 1788px;
  }
  .misc2_anim {
    transform: none;
    width: 88px;
    top: 1709px;
    left: 73px;
  }
}
@media (min-width: 350px) and (max-width: 470px) {
  .pathhide_anim3 {
    top: 70%;
    left: 50%;
    transform: scale(0.7) rotate(2deg) translateX(-50%);
  }
  @keyframes pathhideanim {
    0% {
      left: 50%;
      transform: scale(0.6) rotate(8deg) translateX(-50%);
      top: 26%;
    }
    100% {
      top: 70%;
      left: 50%;
      transform: scale(0.7) rotate(2deg) translateX(-50%);
    }
  }

  .Misc4_anim {
    transform: none;
    width: 60px;
    top: 61%;
    left: 15%;
  }
  .misc1_anim {
    transform: none;
    width: 74px;
    top: 82%;
    right: 4px;
  }
  .geo2_anim3 {
    transform: none;
    left: 17%;
    top: 808px;
    width: 114px;
    transform: translateX(-17%);
  }
  .Geo1_anim3 {
    transform: none;
    width: 100px;
    left: 21%;
    transform: translateX(-21%);
    top: 140%;
  }
  .Tree_anim3 {
    transform: none;
    width: 79px;
    top: 99%;
    left: 73%;
  }
  .Mountain1_anim3 {
    right: 0%;
    transform: translateX(0%);
    top: 1100px;
    width: 150px;
  }
  .bottomtext {
    display: none;
  }
  .trees1_anim {
    transform: none;
    width: 100px;
    top: 240%;
    display: none;
  }
  .geo3_anim {
    transform: none;
    width: 100px;
    right: 10%;
    top: 1697px;
  }
  .misc2_anim {
    transform: none;
    width: 78px;
    top: 1655px;
    left: 58px;
  }
}

@media (min-width: 471px) and (max-width: 767.98px) {
  .pathhide_anim3 {
    top: 59%;
    left: 50%;
    transform: scale(0.8) rotate(2deg) translateX(-50%);
  }

  .Misc4_anim {
    transform: none;
    width: 60px;
    top: 61%;
    left: 15%;
  }
  .misc1_anim {
    transform: none;
    width: 84px;
    top: 65%;
    right: 38px;
  }
  .geo2_anim3 {
    transform: none;
    left: 17%;
    top: 764px;
    width: 114px;
    transform: translateX(-17%);
  }
  .Geo1_anim3 {
    transform: none;
    width: 100px;
    left: 21%;
    transform: translateX(-21%);
    top: 124%;
  }
  .Tree_anim3 {
    transform: none;
    width: 79px;
    top: 103%;
    left: 76%;
  }
  .Mountain1_anim3 {
    right: 0%;
    transform: translateX(0%);
    top: 1100px;
    width: 150px;
  }
  .bottomtext {
    display: none;
  }
  .trees1_anim {
    transform: none;
    width: 100px;
    top: 240%;
    display: none;
  }
  .geo3_anim {
    transform: none;
    width: 100px;
    right: 4%;
    top: 1613px;
  }
  .misc2_anim {
    transform: none;
    width: 78px;
    top: 1138px;
    left: 115px;
  }
  .Anim1_anim {
    opacity: 0;
  }
  .Birds_anim3 {
    transform: none;
    width: 150px;
    top: 600px;
  }
}

@media (min-width: 472px) and (max-width: 575.98px) {
  @keyframes pathhideanim {
    0% {
      left: 50%;
      transform: scale(0.6) rotate(8deg) translateX(-50%);
      top: 26%;
    }
    100% {
      top: 59%;
      left: 50%;
      transform: scale(0.8) rotate(2deg) translateX(-50%);
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  @keyframes pathhideanim {
    0% {
      left: 50%;
      transform: scale(0.7) rotate(8deg) translateX(-50%);
      top: 26%;
    }
    100% {
      top: 59%;
      left: 50%;
      transform: scale(0.8) rotate(2deg) translateX(-50%);
    }
  }
}
@media (min-width: 768px) and (max-width: 1197.98px) {
  .Cloud2_anim3 {
    transform: none;
    top: 286px;
    left: 18%;
    width: 167px;
    transform: translateX(-16%);
  }

  .pathhide_anim3 {
    top: 50%;
    left: 50%;
    transform: scale(1) rotate(2deg) translateX(-50%);
  }
  @keyframes pathhideanim {
    0% {
      left: 50%;
      transform: scale(0.8) rotate(10deg) translateX(-50%);
      top: 26%;
    }
    100% {
      top: 50%;
      left: 50%;
      transform: scale(1) rotate(2deg) translateX(-50%);
    }
  }
  .Cloud1_anim3 {
    transform: none;
    top: 200px;
    right: 0%;
    width: 151px;
  }
  .Misc4_anim {
    transform: none;
    width: 60px;
    top: 61%;
    left: 15%;
  }
  .misc1_anim {
    transform: none;
    width: 106px;
    top: 55%;
    right: 121px;
  }
  .geo2_anim3 {
    transform: none;
    left: 17%;
    top: 764px;
    width: 114px;
    transform: translateX(-17%);
  }
  .Geo1_anim3 {
    transform: none;
    width: 100px;
    left: 21%;
    transform: translateX(-21%);
    top: 124%;
  }
  .Tree_anim3 {
    transform: none;
    width: 79px;
    top: 103%;
    left: 76%;
  }
  .Mountain1_anim3 {
    right: 8%;
    transform: translateX(0%);
    top: 1148px;
    width: 200px;
  }
  .bottomtext {
    display: none;
  }
  .trees1_anim {
    transform: none;
    width: 100px;
    top: 240%;
    display: none;
  }
  .geo3_anim {
    transform: none;
    width: 100px;
    right: 4%;
    top: 1991px;
  }
  .misc2_anim {
    transform: none;
    width: 78px;
    top: 1138px;
    left: 115px;
  }
  .Anim1_anim {
    opacity: 0;
  }
  .Birds_anim3 {
    transform: none;
    width: 150px;
    top: 600px;
  }
}

@media (min-width: 1483px) and (max-width: 1700px) {
  .Misc4_anim {
    top: 50%;
  }
}
@media (min-width: 1601px) and (max-width: 1700px) {
  .pathhide_anim3 {
    left: 30%;
  }
  @keyframes pathhideanim {
    0% {
      top: 30%;
      transform: scale(0.9) rotate(18deg);
      left: 57%;
    }
    100% {
      left: 30%;
      transform: scale(1.5) rotate(2deg);
      top: 38%;
    }
  }
}
@media (min-width: 1350px) and (max-width: 1600px) {
  .pathhide_anim3 {
    left: 25%;
  }
  .geo2_anim3 {
    left: 10%;
  }
}
@media (min-width: 1350px) and (max-width: 1445px) {
  @keyframes pathhideanim {
    0% {
      top: 30%;
      transform: scale(0.9) rotate(18deg);
      left: 52%;
    }
    100% {
      left: 25%;
      transform: scale(1.5) rotate(2deg);
      top: 38%;
    }
  }
}
@media (min-width: 1446px) and (max-width: 1600px) {
  @keyframes pathhideanim {
    0% {
      top: 30%;
      transform: scale(0.9) rotate(18deg);
      left: 57%;
    }
    100% {
      left: 25%;
      transform: scale(1.5) rotate(2deg);
      top: 38%;
    }
  }
}
@media (min-width: 1293px) and (max-width: 1350px) {
  .pathhide_anim3 {
    left: 20%;
    top: 38%;
  }
  @keyframes pathhideanim {
    0% {
      top: 32%;
      transform: scale(0.9) rotate(18deg);
      left: 52%;
    }
    100% {
      left: 20%;
      top: 38%;
      transform: scale(1.5) rotate(2deg);
    }
  }
  .geo2_anim3 {
    left: 10%;
  }
}
@media (min-width: 1200px) and (max-width: 1292px) {
  .pathhide_anim3 {
    top: 38%;
    left: 15%;
    transform: scale(1.5) rotate(2deg);
  }
  @keyframes pathhideanim {
    0% {
      top: 31%;
      transform: scale(0.9) rotate(18deg);
      left: 52%;
    }
    100% {
      top: 38%;
      left: 15%;
      transform: scale(1.5) rotate(2deg);
    }
  }
  .geo2_anim3 {
    left: 10%;
    top: 80%;
  }
}
@media (min-width: 1300px) and (max-width: 1482px) {
  .Misc4_anim {
    top: 60%;
  }
  .trees1_anim {
    left: 0%;
  }
  .geo3_anim {
    top: 170%;
  }
}
@media (min-width: 1200px) and (max-width: 1299px) {
  .trees1_anim {
    left: -10%;
    top: 80%;
  }
  .geo3_anim {
    top: 170%;
  }
}
@media (min-width: 1200px) and (max-width: 1299px) {
  .Misc4_anim {
    top: 70%;
  }
}

.PhotoSet {
  margin: auto;
  width: 100%;
}
.photos h1 {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 50px;
  margin-bottom: 6% !important;
}
.image_row1 {
  margin-top: 100px;
  display: flex;
}
.image_row1 div {
  width: 50%;
}

.image_row3 {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.image_row2 {
  margin-top: 20px;
  display: flex;
  height: 500px;
  justify-content: center;
}
.image_row2 div {
  width: 50%;
}
.align-top {
  align-self: flex-end;
  text-align: center;
}
.image_row3 {
  width: 80%;
  margin: auto;
  margin-top: 50px;
  display: flex;
}
.image_text {
  align-self: center;
  font-size: 40px;
  font-family: Rational;
  text-transform: uppercase;
  font-weight: 300;
  text-align: right;
  margin-right: 20px;
  width: 80%;
}
.image {
  text-align: center;
  align-self: center;
}

* {
  box-sizing: border-box;
}
.PhotoSet .row {
  align-items: center;
  display: flex;
}
.column {
  float: left;
  width: 33.33%;
  padding: 5px;
}

.column > img {
  width: 100%;
  width: 80px;
  margin: 30px auto 70px;
  display: block;
}

.row::after {
  content: '';
  clear: both;
  display: table;
}

.vertical_line {
  border-left: 2px solid darkslategray;
  height: 140px;
  justify-content: center;
  margin-left: 50%;
}

.next_location {
  text-align: center;
}
.next-step {
  cursor: pointer;
}

.footer_text {
  font-size: 46px;
  font-weight: 400;
  transition: 2s;
  opacity: 1;
  overflow: hidden;
  font-family: 'Rational';
  margin-top: 11%;
  cursor: pointer;
}
.text_holder2 {
  display: block;
  margin-top: 7px;
}
.text_holder {
  opacity: 1;
  text-align: right;
  z-index: 2;
  right: 6px;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  padding: 0 10px;
}
.text_holder:after {
  content: '';
  position: absolute;
  bottom: 3px;
  left: 7px;
  right: 0;
  top: 51%;
  background-color: #fddc97;
  border-radius: 20px;
  z-index: -1;
}
.photoSetGrid h1 {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 50px;
}
.photoSetGrid h1 > span {
  display: block;
}
.photoSetGrid ul {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 6%;
  position: relative;
  align-items: flex-start;
}
.photoSetGrid ul li {
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
}
.photoSetGrid ul li > img {
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  max-height: 100%;
  object-fit: cover;
}
.photoSetGrid .instagram-link {
  display: inline-flex;
  align-items: center;
  background: #FFDC97;
  height: calc(1.5vw + 1.5vh);
  margin-top: 1.7vw;
  padding-right: 10px;
  border-radius: 4px;
  line-height: 0;
  font-size: calc(0.8vw + 0.8vh);
}
.photoSetGrid .instagram-link > img {
  height: calc(1.5vw + 1.5vh);
  padding-right: 0.5vw;
}
.photo-option1,
.photo-option2 {
  position: relative;
}
.photo-option2 {
  position: relative;
}
span.top-img img,
span.top-img2 img,
span.mid-img img,
span.mid-img2 img {
  max-width: 100%;
}
span.top-img {
  position: absolute;
  top: 0;
  width: 90px;
  right: 15%;
}
span.mid-img {
  position: absolute;
  bottom: -25%;
  width: 50px;
  right: 26%;
  z-index: 2;
}
span.mid-img2 {
  position: absolute;
  bottom: -25%;
  width: 50px;
  left: 56%;
  z-index: 2;
}
span.top-img2 {
  position: absolute;
  top: 8%;
  width: 90px;
  left: 11%;
}
.photoSetGrid ul.photoSetGridOption1 li:nth-child(1) {
  width: 55%;
  padding: 20.7% 0;
}
.photoSetGrid ul.photoSetGridOption1 li img {
  left: auto;
  right: 0;
}
.photoSetGrid ul.photoSetGridOption1 li:nth-child(2) {
  width: 35%;
  margin-left: 10%;
  margin-top: 28%;
  padding: 23.3% 0;
}
.photoSetGrid ul.photoSetGridOption1 li:nth-child(3) {
  width: 43%;
  margin-left: 12%;
  margin-top: -18%;
}
.photoSetGrid ul.photoSetGridOption1 li:nth-child(3) img {
  position: relative;
}
.photoSetGrid ul.photoSetGridOption2 li:nth-child(1) {
  width: 100%;
  padding: 20.7% 0;
}
.photoSetGrid ul.photoSetGridOption2 li:nth-child(1) > img {
  width: 55%;
  right: 0;
  left: auto;
  display: block;
}
.photoSetGrid ul.photoSetGridOption2 li:nth-child(2) {
  width: 35%;
  padding: 13.15% 0;
}
.photoSetGrid ul.photoSetGridOption2 li:nth-child(2) img {
  left: auto;
  right: 0;
}
.photoSetGrid ul.photoSetGridOption2 li:nth-child(3) {
  width: 100%;
  margin-top: -10%;
}
.photoSetGrid ul.photoSetGridOption2 li:nth-child(3) img {
  width: 40%;
  display: block;
  right: 13%;
  left: auto;
  position: relative;
  margin: 0 auto;
  margin-right: 0;
}

.photoSetGrid .photoGridContent1,
.photoSetGrid .photoGridContent2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  padding-left: 7%;
  margin: 7% 0 0;
  width: 100%;
}
.photoSetGrid .photoGridContent1 img,
.photoSetGrid .photoGridContent2 img {
  position: static;
  height: auto;
  width: 170px;
}
.photoSetGrid .photoGridContent1 span,
.photoSetGrid .photoGridContent2 span {
  width: calc(100% - 200px);
  font-family: 'Rational';
  font-size: 38px;
  font-weight: 400;
}
.photoSetGrid .photoGridContent2 span {
  text-align: right;
}

.bottom-img img {
  width: 100%;
}

.bottom-img {
  width: 50%;
  margin-top: 9%;
  height: 100px;
  overflow: hidden;
  position: relative;
}
.bottom-img:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #f6f3ed 0%, rgba(246, 243, 237, 0) 100%);
  transform: rotate(-180deg);
}
@media screen and (max-width: 991px) {
  span.mid-img,
  span.mid-img2 {
    bottom: -33%;
    width: 35px;
  }
}

@media screen and (max-width: 767px) {
  .photoSetGrid h1 {
    font-size: 30px;
  }
  .photoSetGrid ul.photoSetGridOption1 li.photoGridContent1 span {
    font-size: 28px;
  }
}

@media screen and (max-width: 575px) {
  .photoSetGrid h1 {
    font-size: 24px;
  }
  .photoSetGrid ul li {
    list-style: none;
    position: relative;
    width: 100% !important;
    padding: 0 !important;
    margin: 25px auto !important;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .photoSetGrid ul li img {
    position: static !important;
    width: 100% !important;
    margin: 0 auto !important;
  }
  .photoSetGrid .photoGridContent1 img {
    width: 60px;
    margin: 0 auto;
    margin-left: 0;
  }
  .photoSetGrid .photoGridContent2 img {
    width: 60px;
    margin: 0 auto;
    margin-right: 0;
  }
  .photoSetGrid .photoGridContent1 span,
  .photoSetGrid .photoGridContent2 span {
    width: calc(100% - 80px);
    font-size: 22px;
    margin-top: 20px;
  }
  .footer_text {
    font-size: 20px;
    width: 100%;
  }
  .text_holder {
    width: 100%;
  }
  .text_holder:after {
    top: auto;
    height: 10px;
  }
  .text_holder2 {
    margin-top: 0;
  }
  .bottom-img {
    width: 100%;
  }
  span.mid-img,
  span.mid-img2,
  span.top-img,
  span.top-img2 {
    display: block;
    position: static;
    width: 50px;
    margin: 60px auto 20px;
  }
  span.top-img,
  span.top-img2 {
    position: absolute;
    top: 31%;
    right: 9%;
    width: 50px;
    display: none;
  }
}
.illutration-animation {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.landing-container {
  width: 100%;
  margin: auto;
  display: flex;
  overflow-y: hidden;
  position: relative;
  overflow-x: hidden;
  transition: height 3s;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.landing-container_animscroll {
  width: 100%;
  margin: auto;
  display: flex;
  overflow-y: scroll;
  height: 109vh;
  position: relative;
  overflow-x: hidden;
  transition: height 3s;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.Cloud1 {
  transform: scale(0.06);
  position: absolute;
  top: -229px;
  right: -844px;
  opacity: 1;
  
}
.Cloud1_anim {
  transform: scale(0.09);
  position: absolute;
  top: -95px;
  right: -702px;
  opacity: 1;
  
}
.Cloud1_anim3 {
  transform: scale(0.15);
  position: absolute;
  top: -7px;
  right: -753px;
  opacity: 1;
  
}

.Cloud2 {
  transform: scale(0.06);
  position: absolute;
  top: -228px;
  left: -812px;
  
}
.Cloud2_anim {
  transform: scale(0.09);
  position: absolute;
  top: -199px;
  left: -712px;
  
}
.Cloud2_anim3 {
  transform: scale(0.2);
  position: absolute;
  top: -152px;
  left: -595px;
  
}

.Tree {
  transform: scale(0.1);
  position: absolute;
  top: 19%;
  left: 45%;
  opacity: 1;
  
}
.Tree_anim {
  transform: scale(0.2);
  position: absolute;
  top: 20%;
  left: 40%;
  opacity: 1;
  
}
.Tree_anim3 {
  transform: scale(0.2);
  position: absolute;
  top: 65%;
  left: 0%;
  opacity: 1;
  
}

.Birds {
  transform: scale(0.2);
  position: absolute;
  top: 114px;
  right: 227px;
  opacity: 0;
  
}

.Birds_anim {
  transform: scale(0.2);
  position: absolute;
  top: -41px;
  right: 520px;
  opacity: 1;
  
}
.Birds_anim3 {
  transform: scale(0.3);
  position: absolute;
  top: -22px;
  right: 710px;
  opacity: 1;
  
}

.Geo2 {
  transform: scale(0.1);
  position: absolute;
  top: 401px;
  opacity: 0;
  left: -32px;
}

.Geo3 {
  transform: scale(0.1);
  position: absolute;
  top: 461px;
  left: 202px;
  opacity: 0;
}

.Geo1 {
  transform: scale(0);
  position: absolute;
  right: -639px;
  top: 361px;
  opacity: 0;
  
}
.Geo1_anim {
  transform: scale(0);
  position: absolute;
  right: -639px;
  top: 361px;
  opacity: 0;
  
}
.Geo1_anim3 {
  transform: scale(0.12);
  position: absolute;
  right: -639px;
  top: 1100px;
  opacity: 1;
  
}

.text {
  font-size: 46px;
  width: 36%;
  position: absolute;
  top: 37%;
  left: 11%;
  height: 100px;
  font-weight: 400;
  opacity: 1;
  overflow: hidden;
  font-family: 'Rational';
  
}
.text_anim {
  font-size: 46px;
  width: 36%;
  position: absolute;
  top: 35%;
  left: 11%;
  height: 159px;
  font-weight: 400;
  
  opacity: 1;
  overflow: hidden;
  font-family: 'Rational';
}
.text_anim3 {
  font-size: 46px;
  width: 36%;
  position: absolute;
  top: 25%;
  left: 11%;
  height: 420px;
  font-weight: 400;
  
  opacity: 1;
  overflow: hidden;
  font-family: 'Rational';
  padding-left: 16px;
}
.line {
  width: 676px;
  height: 44px;
  filter: blur(14px);
  background-color: #f6f3ed;
  position: absolute;
  top: 433px;
  left: 135px;
  
}
.line_anim {
  width: 676px;
  height: 44px;
  filter: blur(14px);
  background-color: #f6f3ed;
  position: absolute;
  top: 484px;
  left: 135px;
  
}
.line_anim3 {
  width: 676px;
  height: 44px;
  filter: blur(14px);
  background-color: #f6f3ed;
  position: absolute;
  top: 687px;
  left: 135px;
  
  opacity: 0;
}

.backbox {
  width: 93% !important;
  height: 27px;
  background-color: #fddc97;
  opacity: 1;
  text-align: right;
  z-index: -1;
  right: 6px;
  border-radius: 20px;
  position: absolute;
  bottom: 3px;
}
.backboxmain {
  display: inline-block;
  position: relative;
  padding-left: 16px;
  padding-right: 7px;
  margin-left: -17px;
}
.text_animhide {
  font-size: 46px;
  width: 588px;
  position: absolute;
  top: 244px;
  left: 309px;
  
  font-weight: 400;
  line-height: 55px;
  height: 300px;
  overflow: hidden;
  font-family: 'Rational';
  opacity: 0;
}

.spacing {
  margin-top: -36px;
  margin-left: 11px;
  opacity: 1 !important;
  position: relative;
}
.spacing2 {
  margin-top: -179px;
  margin-left: -9px;
  transform: rotate(-2deg);
  transform-origin: top;
  opacity: 0;
  transition: 1s;
  position: relative;
}
.spacing2_anim {
  margin-top: -179px;
  margin-left: -9px;
  transform: rotate(-2deg);
  transform-origin: top;
  opacity: 1;
  transition: 1s;
  position: relative;
}

.textalign1 {
  text-align: center;
  color: #e78f5f;
  opacity: 0;
}
.textalign2 {
  text-align: center;
  color: #093856;
  opacity: 0;
}
.textalign1_zoom {
  text-align: center;
  opacity: 1;
  transition: 1s;
  transition-delay: 1s;
}
.textalign1_zoom a {
  color: #e78f5f;
}
.textalign2_zoom {
  text-align: center;
  opacity: 1;
  transition: 1s;
  transition-delay: 1s;
}
.textalign2_zoom a {
  color: #093856;
}

.uppercase {
  font-family: Rational;
  font-size: 20px;
}

.hide {
  opacity: 0.01;
  
  transition-delay: 1s;
}
.hide_anim {
  transition: 1s;
  opacity: 0;
  visibility: hidden;
}

.shapehide {
  opacity: 0;
  
}
.shapehide_anim {
  opacity: 1;
  
}

.pathhide {
  position: absolute;
  top: 20%;
  transform: scale(0.6);
  transition: all 1s ease-in-out;
  transform-origin: top left;
  height: 1387px;
  left: 57%;
}
.pathhide_anim {
  position: absolute;
  top: 25%;
  transform: scale(0.9) rotate(18deg);
  transition: all 1.5s ease-in-out;
  transform-origin: top left;
  left: 57%;
}
.pathhide_anim3 {
  position: absolute;
  animation-name: pathhideanim;
  animation-duration: 2s;
  top: 38%;
  left: 35%;
  transform: scale(1.5) rotate(2deg);
  transform-origin: top left;
}

@keyframes pathhideanim {
  0% {
    top: 25%;
    transform: scale(0.9) rotate(18deg);
    left: 57%;
  }
  100% {
    top: 38%;
    left: 35%;
    transform: scale(1.5) rotate(2deg);
  }
}
.Mountain {
  position: absolute;
  right: 109px;
  top: 306px;
  transform: scale(0.8);
  
}
.Mountain_anim {
  position: absolute;
  left: 130px;
  top: 1972px;
  transform: scale(0.5);
  opacity: 1;
  
}
.Mountain1 {
  position: absolute;
  right: -243px;
  top: -78px;
  transform: scale(0.2);
  opacity: 1;
  
}
.Mountain1_anim {
  position: absolute;
  right: -213px;
  top: 174px;
  transform: scale(0.3);
  opacity: 1;
  
}
.Mountain1_anim3 {
  position: absolute;
  right: -116px;
  top: 434px;
  transform: scale(0.4);
  opacity: 1;
  
  z-index: -1;
}
.bottomtext {
  font-family: 'Roboto', sans-serif;
  position: fixed;
  bottom: 4%;
  left: 10%;
  font-size: 12px;
  line-height: 17px;
}
.misc1 {
  transform: scale(0.1);
  right: -50px;
  position: absolute;
  top: 188px;
  opacity: 0;
  
}
.misc1_anim {
  transform: scale(0.2);
  right: 18px;
  position: absolute;
  top: 188px;
  opacity: 1;
  
}
.Anim1 {
  transform: scale(0.1);
  opacity: 0;
  top: 500px;
  position: absolute;
  left: 150px;
  
}
.Anim1_anim {
  transform: scale(0.2);
  opacity: 1;
  top: 110%;
  position: absolute;
  left: 30%;
  
}
.geo3 {
  transform: scale(0.1);
  top: 1941px;
  position: absolute;
  right: 0px;
  opacity: 0;
  
}
.geo3_anim {
  transform: scale(0.3);
  top: 1626px;
  position: absolute;
  right: -100px;
  opacity: 1;
  
}
.misc2 {
  position: absolute;
  top: 1603px;
  left: 204px;
  transform: scale(0.1);
  opacity: 0;
  
}
.misc2_anim {
  position: absolute;
  top: 1703px;
  left: 304px;
  transform: scale(0.4);
  opacity: 1;
  
}
.geo2 {
  position: absolute;
  top: 55%;
  left: -2%;
  transform: scale(0.1);
  
}
.geo2_anim {
  position: absolute;
  top: 55%;
  left: 10%;
  transform: scale(0.2);
  
}
.geo2_anim3 {
  position: absolute;
  top: 65%;
  left: 20%;
  transform: scale(0.3);
  
}

.boat {
  opacity: 0;
}
.boat1 {
  position: absolute;
  bottom: -1174px;
  left: 200px;
  opacity: 1;
}
.tinymountain {
  opacity: 0;
}
.tinymountain1 {
  position: absolute;
  bottom: -1170px;
  right: 27px;
  opacity: 1;
}
.trees1 {
  opacity: 0;
}
.trees1_anim {
  position: absolute;
  top: 70%;
  transform: scale(0.1);
  left: 10%;
}
.outside-illus {
  position: absolute;
  transform: scale(0.18);
  left: -291px;
  top: 93px;
}
.outside-illus1 {
  transform: scale(0.18);
  position: absolute;
  top: 35px;
  left: -601px;
}
.Misc4 {
  transform: scale(0.1);
  opacity: 0;
  left: 5%;
  
}
.Misc4_anim {
  position: absolute;
  top: 43%;
  transform: scale(0.5);
  left: 10%;
  
}
.active {
  display: block;
}
.unactive {
  display: none;
}

/* PERFORMANCE BOOST in animation */

.Birds_anim3 {
  transform: matrix(0.5, 0, 0, 0.5, 0, 0);
}

.LandingPath,
.LandingPath .--pixContainer {
  position: relative;
}
.App .--content {
  padding: 0 !important;
  padding-top: 0px !important;
  -webkit-transform: translate3d(0, 0, 0);
  min-height: 100vh;
}
.Archive,
.Join,
.About,
.photoSetGrid {
  padding: 9vw 5vw 5vw;
}
.pathhide_anim4 {
  z-index: 999;
}
.App,
body {
  overflow: auto;
}
html {
  overflow: auto;
}
.pathhide_anim4 {
  top: 0%;

  transform: scale(0.6);
  height: 1387px;
  position: absolute;
  left: 40% !important;
  height: 100vh;
  overflow: hidden;
  padding-top: 24vh;
  transition: all 0.2s ease;
}
.pathhide_anim4 .shapehide_anim {
  opacity: 0;
}
.pathhide_anim4 .outside-illus {
  opacity: 0;
}
.pathhide_anim4 foreignObject {
  opacity: 0;
  transition: all 0.1s ease;
}
.text_anim3 {
  font-size: 46px;
  width: 43%;
  position: absolute;
  top: 25%;
  left: 10%;
  height: 420px;
  font-weight: 400;
  
  opacity: 1;
  overflow: hidden;
  font-family: 'Rational';
  padding-left: 16px;
  z-index: 99;
}
.sec1 {
  position: absolute;
  top: 0;
  height: 50vh !important;
}

.sec2 {
  height: auto;
}
.sc-bdfBwQ.dxTpiM {
  height: auto;
  position: relative;
  overflow: auto;
}
body {
  overflow-y: scroll;
  overflow: hidden;
}
.LandingPath {
  width: 100%;
  overflow-y: auto;
  height: 100vh;
}

.wrapperdiv {
  position: absolute;
  top: 171px;
  left: -70px;
  width: 100%;
  height: 800px;
}
.App {
  overflow: hidden !important;
}
.line_anim3 {
  height: 44px !important;
  filter: none;
  background-color: none;

  opacity: 1;

  background: -moz-linear-gradient(
    top,
    rgba(246, 243, 237, 1) 45%,
    rgba(246, 243, 237, 1) 66%,
    rgba(246, 243, 237, 0.62) 79%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(246, 243, 237, 1) 45%,
    rgba(246, 243, 237, 1) 66%,
    rgba(246, 243, 237, 0.62) 79%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(246, 243, 237, 1) 45%,
    rgba(246, 243, 237, 1) 66%,
    rgba(246, 243, 237, 0.62) 79%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f3ed', endColorstr='#00ffffff',GradientType=0 );
  transform: rotate(180deg) translateY(-8px);
  z-index: 9999;
  top: 50%;
  left: 0;
}

.Birds_anim3 {
  top: 100px;
  right: 110px;
  opacity: 0;
  transform: scale(0.1);
}
.text_anim3 {
  font-size: 46px;
  position: absolute;
  top: 40vh;
  left: 10%;
  height: 100px;
  font-weight: 400;
  opacity: 1;
  overflow: hidden;
  font-family: 'Rational';
  padding-left: 16px;
  z-index: 99;
}

.text_animation {
  font-size: 46px;
  height: 120px;
  font-weight: 400;
  overflow: hidden;
  font-family: 'Rational';
  padding-left: 16px;
  width: 530px;
  pointer-events: none;
  z-index: 99;
}

.text_gradient {
  width: 100%;
  height: 50px;
  position: absolute;
  filter: blur(12px);
  bottom: 0px;
  left: 0px;
  background: rgb(246, 243, 237);
  border-radius: 10px;
  pointer-events: none;
}

.text_gradient_hide {
  opacity: 0;
}

#pin-section {
  width: 100%;
  height: 100vh;
  position: relative;
  margin: 0 0 500px;
}

.Cloud2_anim3 {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  right: inherit;
  width: auto;

  height: 66px;
  top: 91px;
  left: -4%;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.SRLCaptionText {
  display: none;
}

#trigger2 {
  width: 100%;
  height: 0px;
  position: absolute;
  top: 70vh;
}

.Cloud1_anim3 {
  top: 40%;
  right: -111px;

  height: 77px;
  transform: none;

  animation-name: floating;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
@media (max-width: 1700px) and (min-width: 1483px) {
  .Misc4_anim {
    top: 60%;
  }
}

@media (min-width: 1920px) and (max-width: 4000px) {
  .sec1 {
    height: 650px !important;
  }
  .landing-container_animscroll1 {
    max-width: 1920px;
    width: 100%;
    position: relative;
    margin: 0 auto;
  }
  .wrapperdiv {
    position: absolute;
    top: 50vh;
  }

  .text_anim3 {
    height: 149px;
    left: 100px;
    top: 50%;
    margin-top: -400px;
    width: 740px;
    font-size: 4em;
  }
  .line_anim3 {
    top: 33.5%;
    width: 47%;
    left: 100px;
    height: 100px;
    opacity: 1;
    transform: rotate(180deg);
  }
  .Cloud1_anim3 {
    top: 15%;
  }
  .backbox {
    width: 350px;
  }
}

/* Ipad Portrait */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .wrapperdiv {
    top: 371px;
  }
  .line_anim3 {
    top: 47% !important;
  }
}

/* Ipad Landscape */
@media only screen and (min-width: 1024px) and (orientation: landscape) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .wrapperdiv {
    top: 371px;
  }
  .line_anim3 {
    top: 47%;
  }
  .Cloud1_anim3 {
    top: 19%;
  }
}

@media (max-width: 768px) {
  @keyframes floating {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, 5px);
    }
    100% {
      transform: translate(0, -0px);
    }
  }

  .SideNav .--corp-logo img {
    width: 33px !important;
  }

  .SideNav .--hamburger {
    padding: 15px 0px;
  }
  .SideNav .hamburger--elastic.is-active .hamburger-inner::after {
    top: 1.02vw !important;
  }
  .Cloud2_anim3 {
    top: 4% !important;
    left: -90px !important;
  }

  .text_anim3 {
    top: 160px;
    left: 11px;
    width: 70%;
    height: 75px;
    font-size: 32px;
  }
  .line_anim3 {
    top: 206px;
    transition-delay: 0.7s;
    max-width: 80%;
  }
  .Cloud1_anim3 {
    top: 9%;
    right: -127px;
    height: 55px;
  }
  .Cloud2_anim3 {
    top: 7% !important;
    left: -114px !important;
    height: 50px;
  }
  .Birds_anim3 {
    transform: none;
    width: 50px;
    top: 60px !important;
    height: 0px !important;
    width: auto !important;
  }

  .pathhide_anim4 {
    top: 163px;
    height: auto;
    left: 50% !important;
    overflow: hidden;
    padding-top: 0vh;
    transition: all 2s ease;
    transform: none !important;
    width: 400px;
    transform: none;
    right: auto;
    margin-left: -250px;
    padding-top: 19vh;
    height: 600px;
    transform: scale(0.5) !important;
  }
  .spacing2_anim {
    margin-top: -179px;
    margin-left: -9px;
    transform: translate(-110px, 10px);
  }
  .wrapperdiv {
    left: 0;
  }
  #trigger2 {
    top: 60vh;
    height: 0px;
  }
}
@media screen and (device-width: 411px) and (device-height: 823px) and (-webkit-device-pixel-ratio: 3.5) and (orientation: portrait) {
}
@media (max-width: 400px) {
  .text_anim3 {
    width: 83%;
  }
  .pathhide_anim4 {
    left: 57% !important;
  }
  .pathhide_anim4 {
    top: 120px;
    height: 600px;
  }
}
@media (max-width: 320px) {
  .text_anim3 {
    width: 76%;
    top: 111px;
    left: 11px;
    transform: none;
    font-size: 25px;
  }
  .line_anim3 {
    top: 143px;
  }
  .backbox {
    width: 139px;
  }
}

@import url('https://use.typekit.net/kak8fzr.css');

$bg-color: #fff; //container background-color;
$basic-dark-color: #212121; //color of the spinner
$border-width: 18px; //width of spinners border
$basic-spinner-dimensions: 100px; //width and height of spinner
$main-spinner-dimensions: $basic-spinner-dimensions - $border-width * 2; //width and height of bigger circle
$small-spinner-dimensions: $main-spinner-dimensions * 0.7; //width and height of smaller circle

body {
  margin: 0;

  font-family: omnes-pro, sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;

  font-style: normal;
  background-color: #f6f3ed;
  // overflow: hidden;
  overflow-x: hidden;
  overflow-y: scroll;

  a {
    text-decoration: none;
    color: #333;
  }

  h1 {
    font-family: 'Rational';
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.--loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // border: 1px solid #0f0;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.8vw;
}

.spinner {
  position: relative;
  width: $basic-spinner-dimensions;
  height: $basic-spinner-dimensions;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    border-width: 4px;
    border-style: solid;
    border-radius: 50%;
  }
}

/* spinner-1 styles */
.spinner.spinner-1 {
  @keyframes rotate-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes anti-rotate-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(-360deg);
    }
  }

  &:before {
    width: $main-spinner-dimensions;
    height: $main-spinner-dimensions;
    border-bottom-color: $basic-dark-color;
    border-right-color: $basic-dark-color;
    border-top-color: rgba($basic-dark-color, 0);
    border-left-color: rgba($basic-dark-color, 0);
    top: 0px;
    left: 0px;
    animation: rotate-animation 1s linear 0s infinite;
  }

  &:after {
    width: $small-spinner-dimensions;
    height: $small-spinner-dimensions;
    border-bottom-color: $basic-dark-color;
    border-right-color: $basic-dark-color;
    border-top-color: rgba($basic-dark-color, 0);
    border-left-color: rgba($basic-dark-color, 0);
    top: ($main-spinner-dimensions - $small-spinner-dimensions) / 2;
    left: ($main-spinner-dimensions - $small-spinner-dimensions) / 2;
    animation: anti-rotate-animation 0.85s linear 0s infinite;
  }
}

.orientation-msg {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f3ed;
  width: 100%;
  height: 100vh;
  z-index: 999999;
}

.orientation-msg p {
  font-family: Rational;
  font-size: 25px;
  width: 80%;
  text-align: center;
}

@media (max-width: 820px) and (orientation: landscape) {
  .orientation-msg {
    visibility: visible;
  }

  * {
    overflow: hidden !important;
  }
}

@media screen and (orientation: portrait) {
  .orientation-msg {
    visibility: hidden;
  }
}

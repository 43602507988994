@font-face {
    font-family: 'Rational';
    src: local('Rational'),
        url('./fonts/Pixel Colours - Rational Mood.otf');
}



// --------------------------------
$leftNav-BaseWidth-mobile: 12vw;
$leftNav-ExpandWidth-mobile: 90vw;
// --------------------------------
$leftNav-BaseWidth-medium: 7vw;
$leftNav-ExpandWidth-medium: 31vw;
// --------------------------------
$leftNav-BaseWidth-wide: 6vh;
$leftNav-ExpandWidth-wide: 31vw;
// --------------------------------
$break-small: 1000px;
$break-large: 1800px;
// --------------------------------
// --------------------------------



@mixin respond-to($media) {
    @if $media==handhelds {
        @media only screen and (max-width: $break-small) {
            @content;
        }
    }

    @else if $media==medium-screens {
        @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) {
            @content;
        }
    }

    @else if $media==wide-screens {
        @media only screen and (min-width: $break-large) {
            @content;
        }
    }
}

// --------------------------------


$subPageHeaderSize: 2.3vw;
$subPageHeaderSizeMobile: 2.7vh;
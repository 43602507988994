@import '../../vars.scss';

.SideNav {
  position: fixed;
  left: 0;
  background-color: white;
  display: inline-block;
  height: 100%;
  width: $leftNav-BaseWidth-medium;
  transition: width 0.3s;

  z-index: 9999;
  text-align: center;

  &.--exp-true {
    width: $leftNav-ExpandWidth-medium;
    transition: width 0.1s;

    @include respond-to(handhelds) {
      width: $leftNav-ExpandWidth-mobile;
    }

    @include respond-to(medium-screens) {
      width: $leftNav-ExpandWidth-medium;
    }

    @include respond-to(wide-screens) {
      width: $leftNav-ExpandWidth-wide;
    }
  }

  @include respond-to(handhelds) {
    width: $leftNav-BaseWidth-mobile;
  }

  @include respond-to(medium-screens) {
    width: $leftNav-BaseWidth-medium;
  }

  @include respond-to(wide-screens) {
    width: $leftNav-BaseWidth-wide;
  }

  .--main-container {
    position: absolute;
    right: 0;
    width: $leftNav-BaseWidth-medium;
    height: 100%;
    top: 0;

    @include respond-to(handhelds) {
      width: $leftNav-BaseWidth-mobile;
    }

    @include respond-to(medium-screens) {
      width: $leftNav-BaseWidth-medium;
    }

    @include respond-to(wide-screens) {
      width: $leftNav-BaseWidth-wide;
    }
  }

  .--navigation-container {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 2.5vw;
  }

  $hamburger-layer-width: 2.5vw;
  $hamburger-layer-height: 0.21vw;
  $hamburger-layer-spacing: 0.3vw;

  @import '../../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss';

  .--hamburger {
    outline: none;
    cursor: pointer;
  }

  .--large-hfrt-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    z-index: -1;
    width: 100%;

    img {
      width: 20%;

      @media screen and (max-width: 600px) {
        width: 40%;
      }
    }
    .svg-img {
      display: none;
      img {
        width: 70%;
      }
    }
  }

  .--corp-logo {
    position: absolute;
    bottom: 1.5vw;
    left: 50%;
    transform: translateX(-50%);

    .--corp-logo-span {
      transform: rotate(270deg) translate(48px, 0px);
      display: inline-block;
      width: 200px;
      font-size: 13px;
    }
    img {
      width: 50px;
    }
  }

  .--exp-nav {
    visibility: hidden;
    list-style-type: none;
    text-align: left;
    margin-top: 10vw;
    font-size: 2vw;

    li {
      margin-top: 1.3vw;

      @include respond-to(handhelds) {
        margin-top: 1.3vh;
      }
    }

    a {
      text-transform: uppercase;
      text-decoration: none;
      position: relative;
      word-wrap: none;
      word-break: none;
      white-space: nowrap;
      outline: none;
      opacity: 0;

      span {
        position: relative;
        z-index: 10;
      }

      &::before {
        content: '';
        width: 100%;
        left: 0;
        bottom: 0.2vw;
        height: 0.9vw;
        background-color: #ffdc97;
        position: absolute;
        z-index: 0;
        border-radius: 100vw;
        clip-path: inset(0% 100% 0 0);

        @include respond-to(handhelds) {
          height: 0.9vw;
        }
      }

      &.__active {
        cursor: unset;

        &::before {
          content: '';
          display: none;
        }
      }
    }

    &.--exp-true {
      visibility: visible;

      a {
        opacity: 1;
        transition: opacity 0.3s;
        transition-delay: 0.3s;

        &::before {
          clip-path: inset(0% 0% 0 0);
          transition: clip-path 0.19s ease-out;
          transition-delay: 0.31s;
        }
      }
    }

    @include respond-to(handhelds) {
      font-size: 6vw;
      margin-top: 10vh;
    }

    @include respond-to(medium-screens) {
    }

    @include respond-to(wide-screens) {
    }
  }
}

.SideNav.active {
  .--large-hfrt-logo {
    a {
      display: none;
    }
    .svg-img {
      display: block;
    }
  }
}

.hfrt-logo {
  position: absolute;
  top: 16%;
  left: calc(50% - 37px);
  transform: rotate(-90deg);
  font-family: 'Rational';
  font-weight: 300;
  font-size: 45px;
  cursor: pointer;
  text-decoration: none;
}

.cloudflyin2 {
  position: absolute;
  top: 40%;
  right: -111px;
  opacity: 1;
  transform: none !important;
  height: 117px;
}

.welcome-text {
  width: 95%;
  margin: 0;
  color: #f58220;
}

.text_animation-wrapper {
  width: 55%;
  height: 100vh;
  display: flex;
  align-items: center;
}

.text_animation {
  position: absolute;
  left: 20%;
  font-size: 46px;
  height: 120px;
  font-weight: 400;
  overflow: hidden;
  font-family: 'Rational';
  padding-left: 16px;
  width: 530px;
  pointer-events: none;
  z-index: 99;
}

.text_gradient {
  width: 650px;
  height: 85px;
  position: absolute;
  bottom: -25px;
  left: -20px;
  background-color: rgb(246, 243, 237);
  pointer-events: none;
}

.text_gradient_hide {
  opacity: 0;
}

#pin-section {
  width: 100%;
  height: 100vh;
  position: relative;
  margin: 0 0 500px;
}

.second-text-height {
  height: 178px;
}

.third-text-height {
  height: 400px;
}

.little-road_wrapper {
  position: absolute;
  right: 270px;
  top: 15%;
  transform: scale(0.2, 0.2) rotate(7.5deg);
  z-index: 100;
}

.little-road_size {
  position: absolute;
  left: -340px;
  transform: rotate(351deg);
}

.little-road_family-image {
  height: 120px;
  width: 220px;
  object-fit: contain;
}

.road-star-and-point_hide {
  opacity: 0;
}

.visible_line,
.road-star-and-point_visible {
  opacity: 1;
}

.hide_line {
  opacity: 0;
}

.photographers_visible {
  visibility: visible;
  opacity: 1;
}

.home-component_size {
  width: 100%;
  position: absolute;
  overflow: hidden;
}

.tiger_bg {
  position: absolute;
  top: 1834px;
  right: -10px;
  transform: scale(0.3, 0.3);
}

.Anim1_anim {
  transform: scale(0.2);
  opacity: 1;
  top: 120%;
  position: absolute;
  left: 5%;
}

.Geo1_anim3 {
  transform: scale(0.12);
  position: absolute;
  right: -639px;
  top: 1000px;
  opacity: 1;
}

.Mountain_anim {
  position: absolute;
  left: 500px;
  top: 1972px;
  transform: scale(0.5);
  opacity: 1;
}

.frstbar,
.scndbar,
.thrdbar {
  transition: all 0.35s linear;
  transform: rotate(0deg);
  transform-origin: 0% 50%;
}
#burgericon.open .frstbar {
  transform: rotate(45deg);
}
#burgericon.open .thrdbar {
  transform: rotate(-45deg);
}
#burgericon.open .scndbar {
  width: 0;
  opacity: 0;
}

@media (min-width: 1919px) {
  .little-road_wrapper {
    top: 20%;
    right: 388px;
  }
}

@media (min-width: 1650px) {
  .Mountain_anim {
    left: 0px;
  }

  .misc2_anim {
    top: 1503px;
  }
}

@media (max-width: 1370px) {
  .misc2_anim {
    top: 1500px;
    left: 200px;
  }

  .trees1_anim {
    top: 80%;
  }
}

@media (max-width: 1251px) {
  .little-road_wrapper {
    right: 150px;
  }

  .text_animation {
    left: 18%;
  }
}

@media (max-width: 1101px) {
  .text_animation {
    left: 10%;
    margin: 0 0 0 20px;
  }

  .backbox {
    width: 260px;
    bottom: 0;
    height: 22px;
  }

  .little-road_wrapper {
    right: 10%;
  }

  .tiger_bg {
    top: 180%;
    right: 0%;
  }

  .Mountain_anim {
    left: 300px;
    top: 1772px;
  }
}

@media (max-width: 971px) {
  .text_animation {
    height: 110px;
    font-size: 40px;
    padding-left: 20px;
    width: 465px;
  }
}

@media (max-width: 891px) {
  .little-road_size {
    left: -280px;
  }
}

@media (max-width: 821px) {
  .text_animation-wrapper {
    left: 5%;
  }

  .text_animation {
    left: 20%;
    top: 5%;
    margin: 0;
  }

  #trigger {
    top: 0;
  }

  #trigger1 {
    top: 0;
  }

  .little-road_wrapper {
    right: 240px;
    top: 30%;
  }

  .tiger_bg {
    right: -10%;
    top: 160%;
  }

  .Anim1_anim {
    left: -5%;
  }

  .Mountain_anim {
    left: 125px;
    top: 1900px;
  }

  .Cloud1_anim3 {
    display: none !important;
  }
}

@media (max-width: 711px) {
  .little-road_wrapper {
    right: 270px;
    top: 25%;
    transform: scale(0.5, 0.5) rotate(0deg);
  }

  .little-road_size {
    position: absolute;
    left: -350px;
    transform: rotate(0deg);
  }

  .little-road_family-image {
    height: 100px;
    width: 170px;
    object-fit: contain;
  }

  .first-little-road_family-image {
    height: 100px;
    width: 150px;
  }

  .Mountain_anim {
    transform: scale(0.4);
  }

  .tiger_bg {
    right: -12%;
    top: 150%;
  }

  .photoset-page_join img {
    width: 180px;
  }

  #burgericon {
    transform: scale(1.4);
  }
}

@media (max-width: 651px) {
  .little-road_wrapper {
    right: 240px;
  }

  .Anim1_anim {
    top: 70%;
    left: -15%;
  }

  .text_animation {
    left: 18%;
  }

  .tiger_bg {
    right: -15%;
    top: 150%;
  }

  .Mountain_anim {
    transform: scale(0.2);
    left: 50px;
  }

  #burgericon {
    transform: scale(1.4);
  }
}

@media (max-width: 601px) {
  .text_animation {
    width: 390px;
  }

  .welcome-text {
    width: 100%;
  }

  .Mountain_anim {
    left: 0px;
    transform: scale(0.3);
  }

  .little-road_wrapper {
    right: 36%;
  }

  .Geo1_anim3 {
    top: 115% !important;
  }

  .misc2_anim {
    top: 1240px !important;
  }

  .tiger_bg {
    top: 160%;
  }

  #burgericon {
    transform: scale(1.4);
  }
}

@media (max-width: 541px) {
  #trigger {
    top: 0;
  }

  .text_animation {
    height: 76px;
    font-size: 32px;
    width: 310px;
    left: 25%;
    top: 8%;
    padding-left: 0;
  }

  .welcome-text {
    text-align: start;
  }

  .backbox {
    width: 180px;
    bottom: 0;
    height: 18px;
  }

  .text_gradient {
    bottom: -42px;
  }

  .little-road_wrapper {
    right: 35%;
    top: 20%;
  }

  .tiger_bg {
    transform: scale(0.2, 0.2);
    top: 900px;
    right: -200px;
  }

  .misc2_anim {
    top: 1350px;
    left: 80px;
  }

  .Anim1_anim {
    left: -35%;
    top: 125%;
  }

  .geo3_anim {
    right: 15% !important;
  }

  .Mountain_anim {
    left: -30px;
    top: 1400px;
    transform: scale(0.2);
  }

  #burgericon {
    transform: scale(1.0);
  }
}

@media (max-width: 501px) {
  .Mountain_anim {
    left: -100px;
  }

  .tiger_bg {
    right: -150px;
  }

  .photoset-page_join img {
    width: 160px;
  }
}

@media (max-width: 471px) {
  .misc2_anim {
    display: none !important;
  }
}

@media (max-width: 450px) {
  .text_animation {
    left: 20%;
  }
}

@media (max-width: 421px) {
  .Mountain_anim {
    top: 1450px;
    left: -130px;
    transform: scale(0.15);
  }

  .Anim1_anim {
    top: 115%;
    transform: scale(0.15);
  }

  .tiger_bg {
    right: -180px;
    transform: scale(0.15, 0.15);
    top: 800px;
  }

  .photoset-page_join img {
    width: 120px;
  }
}

@media (max-width: 386px) {
  .text_animation {
    left: 17%;
    font-size: 28px;
    width: 255px;
    height: 67px;
  }

  .little-road_family-image {
    height: 75px;
  }

  .tiger_bg {
    top: 1189px;
    right: auto;
    transform: scale(0.15, 0.15);
  }

  .Anim1_anim {
    left: -45%;
  }

  .misc2_anim {
    left: 250px;
  }

  .geo3_anim {
    display: none;
  }

  #burgericon {
    transform: scale(0.6);
  }
}

@media (max-width: 341px) {
  #trigger {
    top: 0;
  }

  #trigger1 {
    top: 0;
  }

  .text_animation {
    height: 60px;
    font-size: 25px;
    width: 240px;
    left: 20%;
    top: 8%;
  }

  .backbox {
    width: 147px;
    bottom: 0;
    height: 12px;
  }

  .text_gradient {
    height: 75px;
  }

  .Mountain_anim {
    left: -180px;
  }
}

/* alien styles */

.section {
  height: 100vh;
}

.tween {
  width: 100px;
  height: 100px;
  background-color: red;
  margin: 0 !important;
  position: relative;
  left: calc(50% - 50px);
}

.stagger {
  width: 50px;
  height: 50px;
  left: 700px;
  background-color: green;
  position: relative;
}
.zap {
  position: absolute;
  top: 134%;
  transform: scale(1.5) rotate(2deg);
  left: 31% !important;
  height: auto;
  overflow: visible;
  padding-top: 0vh;
}
.zap foreignObject {
  opacity: 1;
  transition: all 0.2s ease;
}
.zap .shapehide_anim {
  opacity: 1 !important;
}
.zap .outside-illus {
  opacity: 0;
}

.cloudflyin2 {
  position: absolute;
  top: 40%;
  right: -111px;
  opacity: 1;
  transform: none !important;
  height: 117px;
}
@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.hometext {
  height: 503px;
  left: 10%;
}
.homeline {
  top: 415px;
  left: 100px;
  height: 100px;
  opacity: 0;
}

@media (min-width: 1920px) and (max-width: 4000px) {
  .zap {
    top: 64%;
  }
  .birdsflyin {
    top: 120px;
    right: 71vh;
  }
  .cloudflyin2 {
    top: 14%;
  }
  .homeline {
    top: 1000px;
  }
  .hometext {
    left: 100px;
    top: 45.7971%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  .homeline {
    top: 555px;
  }
  .cloudflyin2 {
    top: 23%;
  }
}

@media (max-width: 768px) {
  @keyframes floating {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, 5px);
    }
    100% {
      transform: translate(0, -0px);
    }
  }
  .cloudflyin {
    top: 99px !important;
    left: -11% !important;
    height: 47px;
    width: auto;
  }
  .homeline {
    top: 500px;
    left: 0px;
    opacity: 0;
    height: 0 !important;
    transition-delay: 0s;
    transition-duration: 0.6s;
  }
  .hometext {
    height: 400px;
    left: 11px;
    top: 252px;
  }
  .cloudflyin2 {
    position: absolute;
    top: 35%;
    height: 60px;
  }
  .cloudflyin {
    top: 26% !important;
    left: -11% !important;
    height: 57px;
    width: auto;
  }
  .birdsflyin {
    top: 159px !important;
    right: 20%;
    transform: none;
    height: 110px !important;
  }
  .zap {
    position: absolute;
    top: 800px;
    -webkit-transform: scale(1.27) rotate(2deg);
    -ms-transform: scale(1.27) rotate(2deg);
    transform: scale(1.27) rotate(2deg);
    left: 50% !important;
    right: 0;
    height: 100%;
    overflow: visible;
    transform: scale(1) !important;
    margin-left: -302px;
  }

  .zap .spacing2_anim {
    margin-top: -179px;
    margin-left: -50px;
    transform: translate(-110px, 10px);
  }
  .zap .spacing.spacing2_anim {
    margin-left: -9px;
  }
}
@media (max-width: 400px) {
  .birdsflyin {
    height: 85px !important;
  }

  .zap {
    top: 800px;
    left: 57% !important;
  }
}

.invisible_road {
  visibility: hidden;
}

.photoset-page_join {
  text-align: center;
}

.join-the-trip_img {
  width: 170px;
  margin-left: 10px;
}
